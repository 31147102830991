

.btn-1{
    background-color: rgb(255, 102, 0);
    color: white;
    width: 150px;
    height: 35px;
    border-radius: 30px;
    text-align: center;
    align-content: center;
}


.btn-outlined-1{
    margin-left: 10px;
    width: 150px;
    height: 35px;
    border: 10px;
    border-radius: 30px;
    outline-color:rgb(255, 102, 0);
    outline-style: solid;
    text-align: center;
    align-content: center;
}