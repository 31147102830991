.intro-card-1{
    padding: 20px;
    display: flex;
    justify-content: space-around; 
}

.title-1{
    font-size: 30px;
    color: rgb(255, 102, 0);
}

.my-name-text{
    font-size: 80px;
    color: rgb(20, 20, 69);
    font-weight: 700;
   
}

.profile-content{
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.btn-div{
    margin-top: 20px;
    display: flex;
    
}

.profile-pic{
    padding: 20px;
}


@media screen and (max-width : 700px) {
    .intro-card-1{
        flex-direction: column;
        padding: 20px;
    }

    .profile-pic{
        padding-top: 100px;
    }
}